import { useEffect } from 'react';
import logError from 'utils/logError';

const CSPViolationListener = () => {
  useEffect(
    () => {
      // Attach CSP violation listener when the app loads
      const handleCSPViolation = (event) => {
        const cspViolationDetails = {
          violatedDirective: event?.violatedDirective,
          blockedURI: event?.blockedURI,
          originalPolicy: event?.originalPolicy,
          sourceFile: event?.sourceFile,
        };

        void logError(
          new Error(`CSP Violation - ${event?.violatedDirective} - ${event?.blockedURI}`),
          cspViolationDetails
        );
      };

      window.addEventListener("securitypolicyviolation", handleCSPViolation);

      return () => {
        window.removeEventListener("securitypolicyviolation", handleCSPViolation);
      };
    },
    []
  );

  return null;
};

export default CSPViolationListener;