import React, { useCallback, useMemo, useState, useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Input2, Modal2, Icon } from 'kolkit';
import SelectMultipleLocationsWithPercentageKolkit from 'kolkit/SelectMultipleWithPercentage/SelectMultipleLocationsWithPercentage';

import { saveDashboard, deleteDashboard } from 'actions/listeningV2';
import { DashboardSettingsModel } from 'types/DashboardSettingsModel';

import { generateRangeArray } from 'utils';
import { useDispatch } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import useLoading from 'utils/hooks/useLoading';
import { formatMentionForUI } from 'utils/mentions';
import renderModal from 'utils/HOCs/renderModal';
import { MAX_AUDIENCE_LOCATIONS } from 'config/filters';
import { INSTAGRAM, TIK_TOK } from 'constants/networks';

import { Typography } from 'components/ui';

import styles from './EditDashboardModal.module.scss';

export const MODAL_ID = 'listeningEditDashboard';

export type ModalData = {
  isAnEdition?: boolean;
  monitoringToEdit?: DashboardSettingsModel;
  monitoringIdFromUrl?: string;
  rejectedMentions?: {
    instagram: Record<string, string>,
    tiktok: Record<string, string>,
  };
}

const NB_MENTIONS = 10;
export const PERCENTAGES = generateRangeArray({ from: 10, to: 100, step: 10 });

const DEFAULT_STATE = {
  title: '',
  audienceLocations: [],
  myMention: {
    instagram: '',
    tiktok: '',
  },
  mentionsToTrack: {
    instagram: Array(NB_MENTIONS).fill(''),
    tiktok: Array(NB_MENTIONS).fill(''),
  },
};

const EditDashboardModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { off, show, data } = useModal<ModalData>(MODAL_ID);

  const [dashboardParams, setDashboarParams] = useState<DashboardSettingsModel>(
    DEFAULT_STATE,
  );

  const { loading } = useLoading(MODAL_ID);

  const handleCloseModal = useCallback(
    () => {
      if (!data?.isAnEdition && data?.monitoringToEdit?.id) {
        dispatch(deleteDashboard({ id: data.monitoringToEdit.id }));
      }
      setDashboarParams(DEFAULT_STATE);
      off();
    },
    [dispatch, off, data?.monitoringToEdit, data?.isAnEdition]
  );

  const handleChangeTitle = useCallback(
    ({ value }) => {
      setDashboarParams(p => ({
        ...p,
        title: value
      }))
    },
    []
  );

  const handleChangeLocations = useCallback(
    ({ selected }) => {
      setDashboarParams(p => ({
        ...p,
        audienceLocations: selected
      }))
    },
    [],
  );

  const handleChangeMention = useCallback(
    ({ name, value, sna }) => {
      if (name.startsWith('mentionsToTrack')) {
        setDashboarParams(prev => {
          const newMentionsToTrack = [...prev.mentionsToTrack[sna]];
          newMentionsToTrack.splice(Number(name.slice(-1)), 1, value);
          return {
            ...prev,
            mentionsToTrack: {
              ...prev.mentionsToTrack,
              [sna]: newMentionsToTrack
            }
          };
        });
      } else {
        setDashboarParams(prev => ({
          ...prev,
          [name]: {
            ...prev[name],
            [sna]: value
          }
        }));
      }
    },
    []
  );

  const isRequiredFieldsEmpty = useMemo(
    () => {
      const { title, mentionsToTrack } = dashboardParams;
      return !title.trim()?.length ||
        [...mentionsToTrack.instagram, ...mentionsToTrack.tiktok].every(m => !m?.trim()?.length)
    },
    [dashboardParams],
  );

  const handleSaveDashboard = useCallback(
    () => {
      setDashboarParams(prev => ({
        ...prev,
        myMention: {
          instagram: formatMentionForUI(prev.myMention.instagram),
          tiktok: formatMentionForUI(prev.myMention.tiktok),
        },
        mentionsToTrack: {
          instagram: prev.mentionsToTrack.instagram.map(formatMentionForUI),
          tiktok: prev.mentionsToTrack.tiktok.map(formatMentionForUI),
        }
      }));
      dispatch(
        saveDashboard({
          id: data?.monitoringToEdit?.id,
          monitoringIdFromUrl: data?.monitoringIdFromUrl,
          isAnEdition: !!data?.isAnEdition,
          ...dashboardParams,
          toastMessages: {
            created: {
              title: intl.formatMessage({ id: 'listening.toast.created.title' }),
              subtitle: intl.formatMessage({
                id: 'listening.toast.created.subtitle',
              }),
            },
            error: {
              title: intl.formatMessage({ id: 'listening.toast.error.title' }),
            },
            updated: {
              title: intl.formatMessage({ id: 'listening.toast.updated.title' }),
            },
          },
        }),
      );
    },
    [dispatch, intl, data, dashboardParams]
  );

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: `global.cta.${data?.isAnEdition ? 'update' : 'create'}`}),
        onClick: handleSaveDashboard,
        disabled: isRequiredFieldsEmpty || loading,
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel'}),
        onClick: handleCloseModal,
      },
    }),
    [
      intl,
      handleSaveDashboard,
      handleCloseModal,
      isRequiredFieldsEmpty,
      loading,
      data?.isAnEdition,
    ],
  );

  useEffect(() => {
    // When the modal is called with new dashboard to edit, set the local state
    if (data?.isAnEdition && data?.monitoringToEdit?.id) {
      const instagramMentionsWithoutDuplicates = data.monitoringToEdit?.mentionsToTrack?.instagram?.reduce(
        (prev, mention) =>
          mention === data.monitoringToEdit?.myMention?.instagram || prev?.includes(mention)
            ? prev
            : [...prev, mention],
        [] as string[],
      );

      const tiktokMentionsWithoutDuplicates = data.monitoringToEdit?.mentionsToTrack?.tiktok?.reduce(
        (prev, mention) =>
          mention === data.monitoringToEdit?.myMention?.tiktok || prev?.includes(mention)
            ? prev
            : [...prev, mention],
        [] as string[],
      );

      const mentionsToTrack = {
        instagram: Array(NB_MENTIONS).fill(''),
        tiktok: Array(NB_MENTIONS).fill(''),
      };
      if (instagramMentionsWithoutDuplicates) {
        mentionsToTrack.instagram.splice(
          0,
          instagramMentionsWithoutDuplicates?.length,
          ...instagramMentionsWithoutDuplicates,
        );
      }
      if (tiktokMentionsWithoutDuplicates) {
        mentionsToTrack.tiktok.splice(
          0,
          tiktokMentionsWithoutDuplicates?.length,
          ...tiktokMentionsWithoutDuplicates,
        );
      }
      setDashboarParams({
        ...data.monitoringToEdit,
        mentionsToTrack,
      });
    }
  // Ignore data?.monitoringToEdit, only track the ID
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isAnEdition, data?.monitoringToEdit?.id]);

  return (
    <Modal2
      on={show || false}
      onClick={handleCloseModal}
      title={intl.formatMessage({ id: `listening.form.${data?.isAnEdition ? 'edit' : 'add'}.title`})}
      action={actions}
      loading={loading}
    >
      <div className="flex fdc gap16">
        <div className={styles.tile}>
          <div className="flex fdc gap8">
            <Typography variant="label/default">
              <FormattedMessage id="listening.name.label" />
            </Typography>
            <Input2
              fullWidth
              value={dashboardParams.title}
              placeholder={intl.formatMessage({ id: "listening.name.placeholder"})}
              onChange={handleChangeTitle}
              disabled={loading}
              theme="tone"
              size="small"
            />
          </div>

          <div className="flex fdc gap8">
            <Typography variant="label/default">
              <FormattedMessage id="listening.audienceLocation.label" />
            </Typography>
            <SelectMultipleLocationsWithPercentageKolkit
              onChange={handleChangeLocations}
              selected={dashboardParams.audienceLocations}
              placeholder={intl.formatMessage({ id: 'engineSearch.filters.location.placeholder' })}
              size="small"
              max={MAX_AUDIENCE_LOCATIONS}
              percentages={PERCENTAGES}
              countryOnly
            />
          </div>
        </div>

        <div className={styles.tile}>
          <div className="flex fdc">
            <Typography variant="title/section-title-M">
              <FormattedMessage id="listening.form.mentions" />
            </Typography>
            <Typography variant="label/default" className={styles.tertiary}>
              <FormattedMessage id="listening.form.mentions.description" />
            </Typography>
          </div>
          <div className="flex gap16">
            {[INSTAGRAM, TIK_TOK].map(sna => {
              const myMention = dashboardParams.myMention?.[sna];
              const rejectedMentions = data?.rejectedMentions?.[sna];
              const mentionsToTrack = dashboardParams.mentionsToTrack?.[sna];
              const hasMyMentionError = Boolean(myMention && !!rejectedMentions?.[myMention]);
              return (
                <div className="flex fdc gap8 f1" key={`col-${sna}`}>
                  <div className="flex gap8 aic">
                    <Icon label={sna} />
                    <Typography variant="title/section-title-S" className={styles.sna}>
                      {sna}
                    </Typography>
                  </div>
                  <Typography variant="label/default" className={cn({ [styles.hiddenLabel]: sna === TIK_TOK })}>
                    <FormattedMessage id="listening.myMention.label" />
                  </Typography>
                  <Input2
                    fullWidth
                    value={myMention}
                    theme="tone"
                    size="small"
                    name="myMention"
                    placeholder={intl.formatMessage({ id: "listening.myMention.placeholder"})}
                    onChange={({ name, value }) => handleChangeMention({ name, value, sna })}
                    disabled={loading}
                    error={hasMyMentionError}
                    errorMessage={ hasMyMentionError
                      ? intl.formatMessage({ id: `listening.errorMessages.${rejectedMentions?.[myMention]}`})
                      : ''
                    }
                  />
                  <Typography variant="label/default" className={cn({ [styles.hiddenLabel]: sna === TIK_TOK })}>
                    <FormattedMessage id="listening.mentionsMax.label" values={{ max: NB_MENTIONS }} />
                  </Typography>
                  {Array.from(Array(NB_MENTIONS).keys())?.map(index => {
                    const hasError = Boolean(mentionsToTrack[index] && !!rejectedMentions?.[mentionsToTrack[index]]);
                    return (
                      <Input2
                        key={`input-mention-${sna}-${index}`}
                        fullWidth
                        theme="tone"
                        value={mentionsToTrack[index]}
                        className={styles.halfWidth}
                        name={`mentionsToTrack_${index}`}
                        onChange={({ name, value }) => handleChangeMention({ name, value, sna })}
                        disabled={loading}
                        size="small"
                        placeholder={intl.formatMessage(
                          { id: "listening.mentionsInstagram.placeholder" },
                          { index: index + 1 }
                        )}
                        error={hasError}
                        errorMessage={hasError
                          ? intl.formatMessage({ id: `listening.errorMessages.${rejectedMentions?.[mentionsToTrack[index]]}`})
                          : ''
                        }
                      />
                    )
                })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Modal2>
  );
};

export default renderModal(MODAL_ID, EditDashboardModal);
