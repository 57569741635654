import { useEffect, useState, useRef } from 'react';

import { planhatTraker } from 'utils/tags';


const useTrackingLogin = () => {
  const session =  useRef(null);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (!logged) {
      session.current = setInterval(
        () => planhatTraker({
          action: 'Daily activity',
        }).then(() => setLogged(true)),
        1000 * 60 * 60 * 24
      );
    }

    return () => {
      setLogged(false);
      clearInterval(session.current);
    };
    // eslint-disable-next-line
  }, []);

  return true;
}

export default useTrackingLogin;
