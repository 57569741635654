import { useMemo } from 'react';
import { useSelector } from 'utils/redux';

/**
 * Custom hook to create a translator using Intl.DisplayNames
 * @param {string} type - Type of names ('region', 'language')
 * @returns {function} - Translator function to get the localized name
 */
const useIntlDisplayNames = (type) => {
  const localeIso2 = useSelector(({ env }) => env?.locale?.slice(0, 2));

  const displayNames = useMemo(() => {
    try {
      return new Intl.DisplayNames([localeIso2], { type });
    } catch (error) {
      console.error('Invalid locale or type for Intl.DisplayNames:', error);
      return null;
    }
  }, [localeIso2, type]);

  const translate = (code) => {
    if (!displayNames) {
      return code;
    }
    return displayNames?.of(code) || code;
  };

  return translate;
}

export default useIntlDisplayNames;
