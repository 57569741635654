import { get, put, post, remove, patch  } from 'api';
import * as mapper from 'mappers/user';

export const appInit = () => get({
	endpoint: `influence/app_init`,
});

export const noEffectAppInit = () => get({
	endpoint: `influence/app_init`,
	noRetry: true,
	preventEffect: true, // ⚠️ do not remove, this prevents automatic redirection
});

export const getLicence = () => get({
	endpoint: `influence/licence`,
	catchErrors : [403],
});

export const getProjects = state => get({
	endpoint: `influence/projects`,
	params: state,
});

export const logout = () => remove({
	endpoint: `v1/users/logout`,
	debug: true,
	noRetry: true,
	preventEffect: true, // ⚠️ do not remove, this prevents automatic redirection
});

export const updateLocale = locale => patch({
	endpoint: `v1/users/update_locale`,
	params: { locale },
	debug: true,
	catchErrors : [422]
});

export const updateTimezone = timezone => patch({
	endpoint: `v1/users/update_timezone`,
	params: { timezone },
	debug: true,
});

export const updateCurrency = currencyKey => patch({
	endpoint: `v1/users/update_currency`,
	params: { currency: currencyKey },
	debug: true,
});

export const updateDateFormat = dateFormat => patch({
	endpoint: `v1/users/update_date_format`,
	params: { date_format: dateFormat },
	debug: true,
});

export const updateInfo = query => put({
	endpoint: `v1/users/update_info`,
	params: mapper.updateInfo.toApi(query),
	debug: true,
});

export const updateEmail = email => put({
	endpoint: `v1/users/update_email`,
	params: { email },
	catchErrors: [403,422],
	debug: true,
});

export const updatePassword = query => put({
	endpoint: `v1/users/update_password`,
	params: mapper.updatePassword.toApi(query),
	catchErrors: [403,422],
	withCredentials: true,
	debug: true,
});

export const getClients = query => get({
	endpoint: `influence/contacts/search`,
	params: mapper.getClients.toApi(query),
});

export const 	getTags = () => get({
	endpoint: `influence/tags`,
	debug: true,
});

export const addTag = query => post({
	endpoint: `influence/tags`,
	params: mapper.addTag.toApi(query),
	catchErrors: [422],
});

export const updateTag = ({ id, tag }) => put({
	endpoint: `influence/tags/${id}`,
	params: mapper.updateTag.toApi(tag),
	catchErrors: [422, 404],
	debug: true,
});

export const deleteTags = ids => remove({
	endpoint: `influence/tags`,
	params: { tag_ids: ids.map(Number) },
	catchErrors: [422, 404],
	debug: true,
});

export const updateUserPreferences = data => post({
	endpoint: `influence/contact_preferences`,
	params: mapper.userPreferences.toApi(data),
	debug: true,
});

export const getIntegrations = () => get({
	endpoint: `influence/integrations`,
})

export const getCategoryEmails = () => get({
	endpoint: `influence/profiles/custom_emails/categories`,
	debug: true
});

export const getAzureAuthToken = () =>
  get({
    endpoint: 'form_authenticity_token',
    debug: true,
		withCredentials: true,
  });

export const affilaeConnexion = company_id => post({
	endpoint: '/influence/v2/affilae/integrations',
	params: { affilae: { company_id } },
	debug: true,
})

export const affilaeConnexionDestroy = () => remove({
	endpoint: '/influence/v2/affilae/integrations',
	debug: true,
});

export const acceptCgu = () => patch({
	endpoint: `v1/users/accept_terms_of_use`,
	debug: true,
})
