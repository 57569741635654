import { actions } from './searchEngine.reducers';

// Types
export type {
  NetworkTabFilterName,
  SimpleRangeFilter,
  MultipleRangeFilter,
  LocationFilter,
  SliceState,
  SavedSearch,
  LaunchSearchResponse,
} from './searchEngine.types';

// Actions
export const {
  updateKey,
  setDefaultFilters,
  clearAllFilters,
  clearFiltersForNetwork,
  setTermsAndScope,
  setViewMode,
  setSort,
  setPeriod,
  toggleRequiredNetworks,
  setNetworkFiltersOperator,
  setNetworkSimpleRange,
  setNetworkMultipleRange,
  setNetworkMultiple,
  setNetworkMultipleLocation,
  setExcludedCampaigns,
  setExcludedLists,
  setExcludedTags,
  toggleBasicFilter,
  toggleAccountType,
  toggleExcludeFavorites,
  toggleProfileGender,
  saveCurrentFiltersInTemp,
  restoreFiltersFromTemp,
  clearFiltersInTemp,
  loadSavedSearch,
  setPage,
} = actions;

export {
  // Thunk
  exportCurrentSearch,
  initSearch,
  launchSearchStandalone,
  launchSearchFromReduxParams,
  fetchSavedSearches,
  saveSearch,
  deleteSearch,
  saveCurrentFilters,
} from './searchEngine.actions';

export { mapOldSearchParams } from './searchEngine.utils';

export { default } from './searchEngine.reducers';
