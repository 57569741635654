import { getStore } from 'config/store';

const currencies = [
  {
    label: 'Euro',
    value: 'EUR',
    symbol: '€',
  },
  {
    label: 'British Pound Sterling',
    value: 'GBP',
    symbol: '£',
    symbolAlwaysAtStart: true,
  },
  {
    label: 'United States Dollar',
    value: 'USD',
    symbol: '$',
    symbolAlwaysAtStart: true,
  },
  {
    label: 'Norwegian Krone',
    value: 'NOK',
    symbol: 'kr',
  },
  {
    label: 'Swedish Krona',
    value: 'SEK',
    symbol: 'kr',
  },
  {
    label: 'Danish Krone',
    value: 'DKK',
    symbol: 'kr',
  },
];

export const initCurrencySymbol = () => {
  if (!getStore()) return null;
  const index = currencies?.find(
    ({ value }) => getStore()?.env?.currency.value === value,
  );
  return index?.symbol;
};

export type Currency = 'EUR' | 'USD' | 'GBP' | 'NOK' | 'SEK' | 'DKK';

export const DEFAULT_CURRENCY = 'EUR' as const;
export const BASE_CURRENCY_BACKEND = 'USD' as const;
export const BASE_CURRENCY_SHOPIFY = 'EUR' as const;

export default currencies;
