/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/actual';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Provider as JotaiProvider } from 'jotai'
import smoothscroll from 'smoothscroll-polyfill';
import { init as initFullStory } from '@fullstory/browser';

import store from 'config/store';
import { jotaiStore } from 'state/app';

import App from 'components/App';

import 'css/scss/index.scss';

const dom = document.getElementById('root');

smoothscroll.polyfill();

// Delay session capture until we call FS.start().
// We will start after the first call to app_init
window._fs_capture_on_startup = false;
initFullStory({ orgId: 'o-1G4JDF-na1' });

const InfluenceApp = () => (
  <Provider store={store}>
    <JotaiProvider store={jotaiStore}>
      <App />
    </JotaiProvider>
  </Provider>
);

export default InfluenceApp;

if (process.env.NODE_ENV !== 'test') {
  const root = createRoot(dom);
  root.render(<InfluenceApp />)
}

// Hot reloading
if (module && module.hot) {
  module.hot.accept();
}
